<template>
  <div class="px-4 sm:px-0">
    <div
      class="container mx-auto flex h-full flex-col items-center justify-center py-16 sm:py-0"
    >
      <transition
        enter-active-class="miniFadeInTop"
        leave-active-class="miniFadeOutBottom"
        mode="out-in"
      >
        <form
          class="login"
          @submit.prevent="resetPassword"
          novalidate="true"
          v-if="!isSuccess"
        >
          <div class="mb-4 text-2xl font-semibold">Reset password</div>
          <div class="mb-4 w-full">Enter your new password below.</div>
          <div
            v-if="hasError"
            class="error-msg rounded border-2 border-red-400 bg-red-100 p-4 text-red-400"
          >
            There was an error processing your request. Please try again later
          </div>
          <div class="input-group mt-8 w-full">
            <label class="items-end justify-between"
              >New password
              <span class="text-xs italic text-gray-500"
                >minimum of 8 characters</span
              ></label
            >
            <password-input
              v-model="passwordNew"
              :isFocused="true"
              :canView="true"
              :showValid="true"
            />
          </div>
          <div class="input-group w-full">
            <label>Re-enter your new password</label>
            <password-input v-model="passwordNewRepeat" :canView="true" />
          </div>
          <loading-button
            :is-loading="isLoading"
            :is-enabled="formValid"
            :is-full="true"
            label="Reset password"
          />
        </form>
        <div v-else class="text-center">
          <div>
            <font-awesome-icon
              class="mb-8 mr-2 text-5xl text-green-400"
              :icon="['far', 'check']"
            />
          </div>
          <div class="mb-8 font-merri text-2xl">Password reset successful</div>
          <div class="mb-8">
            Your password has succesfully been reset. You can now log into your
            account.
          </div>
          <router-link
            class="mt-8 text-gray-500 hover:underline"
            :to="{ name: 'login' }"
          >
            Login to your account
            <font-awesome-icon :icon="['far', 'arrow-right-long']" class="ml-2"
          /></router-link>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import http from '@/http';
import LoadingButton from '@/components/LoadingButton';
import PasswordInput from '@/components/auth/PasswordInput';
export default {
  metaInfo: {
    title: 'Reset Password'
  },
  data() {
    return {
      passwordNew: '',
      passwordNewRepeat: '',
      isLoading: false,
      hasError: false,
      resetId: this.$route.query.id,
      isSuccess: false
    };
  },
  components: {
    LoadingButton,
    PasswordInput
  },
  computed: {
    formValid() {
      if (
        this.passwordNew.length >= 8 &&
        this.passwordNew &&
        this.passwordNewRepeat &&
        this.passwordNew === this.passwordNewRepeat
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    resetPassword: function () {
      this.isLoading = true;
      let data = {
        id: this.resetId,
        password: this.passwordNew
      };

      http
        .post('/me/password/resetconfirm', data)
        .then(() => {
          this.isLoading = false;
          this.isSuccess = true;

          this.$store.dispatch('sendMpEvent', {
            event: 'Reset Password - Success',
            properties: {
              location: this.$route.name,
              type: 'EVT'
            }
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.hasError = true;

          this.$store.dispatch('sendMpEvent', {
            event: 'Reset Password - Error',
            properties: {
              location: this.$route.name,
              error: error.response.data.error.message,
              type: 'EVT'
            }
          });
          console.log(error);
          throw Error(error.response.data.error.message);
        });
    }
  },
  mounted() {
    this.$store.dispatch('sendMpEvent', {
      event: 'Reset Password - Loaded',
      properties: {
        location: this.$route.name,
        type: 'EVT'
      }
    });
  }
};
</script>

<style lang="scss" scoped>
form {
  max-width: 360px;
  width: 100%;
}
</style>
